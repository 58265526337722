<template>
    <content-wrapper>
        <group-preview
            v-if="currentGroup"
            :item="currentGroup"
            outlined
            class="mb-4"
        />
        <w-data-table
            outlined
            :loading="loading"
            :headers="headers"
            :items="collection"
            searchable
            :filter-by="['last_name', 'first_name', 'phone', 'email']"
        >
            <template #filters>
                <div>
                    <v-select
                        v-if="groups.length > 1"
                        v-model="filterByGroup"
                        :items="groups"
                        item-text="title"
                        item-value="uuid"
                        :placeholder="$trans('Group')"
                        dense
                        outlined
                        hide-details
                        @change="fetchItems({})"
                    >
                        <template #prepend-inner>
                            <w-icon dense value="GROUP" />
                        </template>
                    </v-select>
                </div>
            </template>

            <template #actions>
                <w-btn-action
                    :disabled="!filterByGroup"
                    :text="$trans('Add student')"
                    icon="ADD"
                    @click="onShowForm"
                />
            </template>

            <template #[`item.last_name`]="{item}">
                <member-full-name
                    :item="item"
                />
            </template>

            <!-- actions -->
            <template #[`item.actions`]="{item}">
                <v-btn
                    icon
                    color="success"
                    @click="onShowForm(item)"
                >
                    <w-icon value="EDIT" />
                </v-btn>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isAsideDrawer"
            :title="asideTitle"
        >
            <div v-if="isFormItem" class="px-4 pt-3">
                <v-card
                    outlined
                    class="d-flex align-center px-3"
                >
                    <div class="d-flex justify-center">
                        <member-avatar-form
                            :member="item"
                            member-type="student"
                            :size="60"
                            rounded
                        />
                    </div>
                    <div>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-icon>
                                    <w-icon value="GROUP" />
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item | memberFullName }}</v-list-item-title>
                                    <v-list-item-title>{{ $trans('Group') }}: {{ item.group.title }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.group.level.title }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-card>
            </div>
            <div class="pa-4">
                <student-form
                    :key="formKey"
                    :item="item"
                    :group="filterByGroup"
                    @submit="onSubmit"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import MemberFullName from '@/components/Member/MemberFullName'
import StudentForm from '@apps/school/components/Student/StudentForm'
import ContentWrapper from '@/components/ContentWrapper'
import { getStudentsCollection } from '@apps/school/api/students'
import MemberAvatarForm from '@apps/auth/components/MemberAvatarForm'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
import { getGroupsCollection } from '@apps/school/api/groups'
import GroupPreview from '@apps/school/components/Group/GroupPreview'
export default {
    name: 'StudentsCuratorManage',
    components: {
        GroupPreview,
        MemberAvatarForm,
        ContentWrapper,
        StudentForm,
        MemberFullName
    },
    mixins: [
        hasMemberFullNameFilterMixin
    ],
    computed: {
        isFormItem() {
            return Object.keys(this.item).length > 0
        },
        asideTitle() {
            return Object.keys(this.item).length === 0
                ? this.$trans('Add student')
                : this.$trans('Update student')
        },
        currentGroup() {
            if(!this.filterByGroup) {
                return null
            }

            return this.groups.find(o => o.uuid === this.filterByGroup)
        },
        headers() {
            return [
                {
                    text: this.$trans('Full name'),
                    value: 'last_name',
                },
                {
                    text: this.$trans('Email'),
                    value: 'email'
                },
                {
                    text: this.$trans('Phone'),
                    value: 'phone'
                },
                {
                    value: 'actions',
                    sortable: false,
                    align: 'right'
                }
            ]
        }
    },
    data() {
        return {
            isAsideDrawer: false,
            loading: false,
            groups: [],
            collection: [],
            filterByLevel: null,
            filterByGroup: null,
            item: {},
            formKey: 0
        }
    },
    mounted() {
        this.onChangeOptions({})
    },
    methods: {
        async fetchGroups() {
            this.$lprogress.begin()

            try {
                const response = await getGroupsCollection({
                    curator: this.$auth.member.uuid,
                    with: 'level.school'
                })

                this.groups = response.data.data
                this.filterByGroup = this.groups[0].uuid
            } finally {
                this.$lprogress.end()
            }
        },
        fetchItems(payload) {
            this.loading = true

            payload = payload || {}
            payload.group = this.filterByGroup
            payload.with = 'group.level'

            getStudentsCollection(payload)
                .then(response => {
                    this.collection = response.data.data
                    this.loading = false
                })
        },
        async onChangeOptions(payload) {
            try {
                if(!this.filterByGroup) {
                    await this.fetchGroups()
                }

                await this.fetchItems(payload)
            } catch (error) {}
        },
        onShowForm(item) {
            this.item = item || {}
            this.isAsideDrawer = true
            this.formKey++
        },
        onSubmit(data) {
            this.item = {}
            this.isAsideDrawer = false
            this.loading = false
            this.fetchItems()
        }
    }
}
</script>

<style lang="scss">

</style>
