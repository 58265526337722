<template>
    <w-entry-preview
        mini
        icon="GROUP"
        :title="`${$trans('Group')}: ${item.title}`"
        :note="item.description"
        :overline="overline"
        :to="to"
        :outlined="outlined"
    >
        <template #actions>
            <slot name="actions"></slot>
        </template>
    </w-entry-preview>
</template>

<script>
export default {
    name: 'GroupPreview',
    props: {
        item: {
            type: Object,
            required: true
        },
        to: {
            type: [ String, Object ],
            default: undefined
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        vCardProps() {
            const props = {}

            if (this.to) {
                props.to = this.to
                props.hover = true
                props.outlined = true
            }

            return props
        },
        overline() {
            if(!this.item.level) {
                return null
            }

            let overline = this.item.level.title

            if(this.item.level.school) {
                overline += ` (${this.item.level.school.title})`
            }

            return overline
        }
    }
}
</script>

<style lang="scss">

</style>
